//==============================
// Genres
//==============================

$('.genre-button').click(function() {
  $('.genre-button').removeClass('active');
  $('.library-table-row').removeClass('hidden');
  var thisItem = $(this);
  thisItem.addClass('active');
  var genre = thisItem.attr('data-genre');
  var genreRow = $('.library-table-row.genre-' + genre +  '');
  $('.library-table-row').addClass('hidden');
  genreRow.removeClass('hidden');
});
