//==============================
// Audio Player
//==============================

$('.music-control-button').click(function() {

  var thisItem = $(this);
  var thisUrl = thisItem.attr('data-audio');
  var audioArtist = thisItem.attr('data-artist');
  var audioTitle = thisItem.attr('data-title');
  var audioPlayer = $('#audio-player');
  var playerId = audioPlayer.attr('data-audio');

  if (thisItem.hasClass('play')) {
    $('.music-control-button').addClass('play');
    $('.music-control-button').removeClass('pause');
    // console.log('play');

    if (thisUrl === playerId) {
      audioPlayer.trigger('play');
    }
    else {
      $('#audio-player').attr('src', thisUrl).attr('data-audio', thisUrl);
    }
  }
  if (thisItem.hasClass('pause')) {
    // console.log('pause');
    $('#audio-player').trigger('pause');
  }

  $('.player').removeClass('hidden');

  thisItem.toggleClass('play').toggleClass('pause');

  $('.music-control-button').removeClass('active');
  thisItem.addClass('active');

  $('.player .player-title').text(audioTitle);
  $('.player .player-artist').text(audioArtist);



  // if (thisItem.hasClass('play')) {
  //   thisItem.removeClass('play').addClass('pause');

  //   if (thisUrl === playerId) {
  //     audioPlayer.trigger('play');
  //   }
  //   else {
  //     $('#audio-player').attr('src', thisUrl).attr('data-audio', thisUrl);
  //   }
  // }
  // else if (thisItem.hasClass('pause')) {
  //   $('#audio-player').trigger('pause');
  //   thisItem.removeClass('pause').addClass('play');
  // }
  
  
  // thisItem.removeClass('play').addClass('pause');
  // $('.music-control-button.play').removeClass('hidden');
  // thisItem.addClass('hidden');
  // $('.player').removeClass('hidden');
  // $('.music-control-button.pause').addClass('hidden');
  // thisItem.next().removeClass('hidden');

  // $('.player .player-title').text(audioTitle);
});

// $('.music-control-button.pause').click(function() {
//   $('#audio-player').trigger('pause');
//   $('.music-control-button.play').removeClass('hidden');
//   $('.music-control-button.pause').addClass('hidden');
// });

$('.player-close').click(function() {
  $('#audio-player').trigger('pause');
  $('.player').addClass('hidden');
  $('.music-control-button').addClass('play');
  $('.music-control-button').removeClass('pause');
  $('.music-control-button').removeClass('active');
});
