//==============================
// Photo Gallery
//==============================

var photoGallery = $('.photo-gallery').masonry({
  itemSelector: '.photo-gallery-item',
  percentPosition: true,
  gutter: 10
});

photoGallery.imagesLoaded().progress( function() {
  photoGallery.masonry('layout');
  setTimeout(function() {
    $('.photo-gallery-item').addClass('show');
  }, 500);
});
