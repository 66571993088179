//==============================
// Testimonials
//==============================

$('.testimonials-items').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  dots: true
});